export const LOCAL = "local";
export const CLOUD = "cloud";
export const DEVELOP = "develop";
export const KIND_OF_MACHINE = [LOCAL, CLOUD, DEVELOP];
export const TYPE_TAG = {
    BANDAMIN: 'BANDAMIN',
    BANDAMAX: 'BANDAMAX'
};

export const COMPANY = {
    CERMAQ: {
        RAHUE: "RAHUE",
        SANTA_JUANA: "SANTA JUANA",
        TRAFUN: "TRAFUN",
        RIO_PESCADO: "RIO PESCADO",
        CANAL_CHACAO: "CANAL CHACAO",
    },
    AQUAGEN: {
        IGNAO: "IGNAO",
        COMAU: "COMAU",
    },
    SASA: {
        ALCALDEO: "ALCALDEO",
        PERAL: "PERAL",
        RUPANQUITO: "RUPANQUITO",
        TAMBORES: "TAMBORES",
    }
}

export const SUPER_USER = "superuser";

export const SYSTEM = {
    RECIRCULACION: "Recirculacion",
    MEE: "Sistema MEE",
    PARAMETRO_RASS: "Parámetros Rass",
    CONTROL_OXIGENO: "Control de Oxígeno"
};

export const ALARM = {
    TODOS: {
        id: 'todos',
        name: 'TODOS'
    },
    FALLA: {
        id: 'falla',
        name: 'FALLA'
    },
    ALARMA: {
        id: 'alarma',
        name: 'ALARMA'
    },
    DESCONEXION: {
        id: 'desc',
        name: 'DESCONEXIÓN'
    }
};

export const TYPE_OF_FAIL = {
    INTERNET: "desc_internet",
    PLC: "desc_plc",
    INSTRUMENTATION: "falla_instrumentacion"
}

export const WORKPLACE_NAME = {
    TRAFUN_CERMAQ: "trafun-cermaq",
    ALCALDEO_SASA: "alcaldeo-sasa",
    RUPANQUITO_SASA: "rupanquito-sasa",
    ATCPATAGONIA_AQUAINOVO: "atcpatagonia-aquainovo",
    IGNAO_AQUAGEN: "ignao-aquagen",
    PETROHUE_CAMANCHACA: "petrohue-camanchaca",
    LABPTOVARAS_AQUAGESTION: "labptovaras-aquagestion",
    TAMBORES_SASA: "tambores-sasa",
    PERAL_SASA: "peral-sasa",
    LOSTILCOS_YADRAN: "lostilcos-yadran",
}
