export const SET_WORKPLACES = 'WORKPLACES/DATA_BY_USER';

export const setWorkplaces = workplaces => ({
    type: SET_WORKPLACES,
    workplaces
});

const initialState = {
    workplaces: [],
}

export default function reducer(state = initialState , action) {
    switch (action.type) {  
        case SET_WORKPLACES:
            return {
                ...state,
                workplaces: [...action.workplaces]
            };
    }
    return state;
}
