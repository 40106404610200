import { createStore } from 'redux';
import reducers from '../reducers';
import { persistStore ,persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createEncryptor from 'redux-persist-transform-encrypt'

const key = process.env.REACT_APP_CODE;
const encryptor = createEncryptor({
  secretKey: key,
});

const persistConfig = {
  key: 'root',
  storage,
   transforms: [encryptor]
};
const rootReducer = {
  ...reducers
};
const persistCombinedReducers = persistCombineReducers(persistConfig, rootReducer);

export const store = createStore(
    persistCombinedReducers,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
export const persistor = persistStore(store);

