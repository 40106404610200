export const SET_COMPANY = 'COMPANY/USER';

export const setCompany = company => ({
    type: SET_COMPANY,
    company
});

const initialState = {
    company: {},
}

export default function reducer(state = initialState , action) {
    switch (action.type) {  
        case SET_COMPANY:
            return {
                ...state,
                company: {...action.company}
            };
    }
    return state;
}
