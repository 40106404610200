import React from 'react';

import Loader from 'react-loaders'

const Charger = () => {
    return (
        <div className="loader-container">
            <div className="loader-container-inner">
                <div className="text-center">
                    <Loader type="ball-pulse"/>
                </div>
                <h6 className="mt-3">
                    Cargando...
                    <small>Espere unos segundos..</small>
                </h6>
            </div>
        </div>
    );
};

export default Charger;