export const SET_USUARIO = 'SESSION/USUARIO';
export const SET_NAME_USUARIO = 'SESSION/NAME_USUARIO';
export const SET_EMAIL_USUARIO = 'SESSION/EMAIL_USUARIO';
export const SET_ROL_USUARIO = 'SESSION/ROL_USUARIO';

export const setUsuario = usuario => ({
    type: SET_USUARIO,
    usuario
});

export const setNameUsuario = nameUsuario => ({
    type: SET_NAME_USUARIO,
    nameUsuario
});

export const setEmailUsuario = emailUsuario => ({
    type: SET_EMAIL_USUARIO,
    emailUsuario
});

export const setRolUsuario = rolUsuario => ({
    type: SET_ROL_USUARIO,
    rolUsuario
});

const initialState = {
    usuario: null,
    nameUsuario: null,
    emailUsuario: null,
    rolUsuario: null
}

export default function reducer(state = initialState , action) {
    // eslint-disable-next-line default-case
    switch (action.type) {
        case SET_USUARIO:
            return {
                ...state,
                usuario: action.usuario
            };
        case SET_NAME_USUARIO:
            return {
                ...state,
                nameUsuario: action.nameUsuario
            };
        case SET_EMAIL_USUARIO:
            return {
                ...state,
                emailUsuario: action.emailUsuario
            };
        case SET_ROL_USUARIO:
            return {
                ...state,
                rolUsuario: action.rolUsuario
            };
    }
    
    return state;
}
