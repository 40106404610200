import { Route,Redirect } from 'react-router-dom';
import React, { Suspense, lazy, Fragment } from 'react';

import { ToastContainer } from 'react-toastify';

import Charger from 'Components/Charger';

const UserPages = lazy(() => import('../../Pages/UserPages'));
const AdminPages = lazy(() => import('../../Pages/Admin'));

const AppMain = () => {
    return (
        <Fragment>
            <Suspense fallback={<Charger />}>
                <Route path="/pages" component={UserPages} />
            </Suspense>
            <Suspense fallback={<Charger />}>
                <Route path="/admin" component={AdminPages} />
            </Suspense>

            <Route exact path="/" render={() => (
                <Redirect to="/pages/login"/>
            )}/>
            
            <ToastContainer />
        </Fragment>
    );
};

export default AppMain;