import React from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react'

import * as serviceWorker from './serviceWorker';

import { HashRouter } from 'react-router-dom';
import './assets/base.scss';
import Main from './Pages/Main';
import   { store, persistor } from './config/configureStore';
import { Provider } from 'react-redux';
import { KIND_OF_MACHINE } from 'utils/constantes';

const mystore = store;
const rootElement = document.getElementById('root');

const renderApp = Component => {
    let appRender = (
        <Provider store={mystore}>
            <PersistGate loading={null} persistor={persistor}>
                <HashRouter>
                    <Component />
                </HashRouter>
            </PersistGate>
        </Provider>
    );

    if(!KIND_OF_MACHINE.includes(process.env.REACT_APP_MACHINE))
    {
        appRender = (
            <h3>
                <strong>Error: Tienes que incorporar el REACT_APP_MACHINE en el fichero .env</strong>
            </h3>
        );
    }

    ReactDOM.render( appRender, rootElement );
};

renderApp(Main);

if (module.hot) {
    module.hot.accept('./Pages/Main', () => {
        const NextApp = require('./Pages/Main').default;
        renderApp(NextApp);
    });
}

serviceWorker.unregister();

